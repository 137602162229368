import styled from "styled-components";

// TODO: This is somhow deeply screwed.

export const CommunityCardHolderDiv = styled.div`
  padding: 0;
  /* padding: var(--insidePadding); */
  display: flex;
  flex-wrap: wrap;
  width: var(--width);
  margin: 50px auto 0;
  justify-content: center;
  position: relative;
  /* background-color: var(--background); */
  & .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -20px; /* gutter size offset */
    width: auto;
  }
  & .my-masonry-grid_column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
    width: 315px !important;
    &:nth-child(1),
    &:nth-child(5) {
      margin-top: 168px;
    }
    &:nth-child(3),
    &:nth-child(7) {
      margin-top: 184px;
    }
    &:nth-child(4),
    &:nth-child(8) {
      margin-top: 115px;
    }
  }

  & .my-masonry-grid_column > div {
    margin-bottom: 20px;
  }
  & div.placeholder {
    height: 295px;
    background-color: var(--red);
  }
  @media (max-width: 767px) {
    & .my-masonry-grid_column {
      &:nth-child(1),
      &:nth-child(5) {
        margin-top: 0;
      }
      &:nth-child(3),
      &:nth-child(7) {
        margin-top: 0;
      }
      &:nth-child(4),
      &:nth-child(8) {
        margin-top: 0;
      }
    }
  }
`;

export const CommunityCard = styled.div`
  width: 295px;
  margin-bottom: 12px;
  cursor: pointer;
  & h5 {
    margin: 8px 0 0 0;
  }
  & > a {
    color: var(--black);
    & > img {
      width: 100%;
      height: auto;
    }
    & > div {
      /* background-color: var(--red);
      width: 100%;
      min-height: 400px; */
    }
  }
  @media (max-width: 767px) {
    & h5 {
      text-align: center;
    }
  }
`;

export const OverlayDiv = styled.div`
  /**
* myColumn comes in as 0, 1, 2, or 3
*
* if it's 0 or 1, text block should be on right
* if it's 1 or 2, text block should be on the left
 */
  position: absolute;
  left: ${(props) => props.left}px;
  left: ${(props) =>
    props.myColumn < 2
      ? 25 + 25 * props.myColumn
      : 25 + 25 * (props.myColumn - 2)}px;
  top: ${(props) => props.top}px;
  width: calc(var(--width) - 100px);
  z-index: 9;
  & > div:first-child {
    background-color: var(--yellow);
    padding: 84px 50px 50px 50px;
    border-top: 4px solid var(--red);
    display: flex;
    flex-direction: ${(props) => (props.myColumn < 2 ? "row" : "row-reverse")};
    & > div#image-contacts {
      /*** THIS IS THE IMAGE + CONTACTS DIV */
      display: flex;
      width: 610px;
      justify-content: space-between;
      flex-direction: ${(props) =>
        props.myColumn === 1 || props.myColumn === 3 ? "row-reverse" : "row"};
      & > div {
        width: 295px;
        & > img {
          width: 295px;
          height: auto;
        }
        & > div + div {
          margin-top: 20px;
        }
      }
      & h4 {
        padding-top: 8px;
        color: var(--red);
        border-top: 4px solid var(--red);
      }
      & p {
        font-size: 18px;
        font-weight: 400;
        line-height: 25.2px;
        margin: 0;
        & + svg {
          margin-top: 18px;
        }
      }
      & > div {
        margin-left: 25px;
        width: 580px;
        min-width: 295px;
        & h3 {
          &:first-of-type {
            color: var(--red);
            & + h3 {
              margin-bottom: 30px;
            }
          }
        }
        & h4 {
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.84px;
          margin-bottom: 4px;
        }
        & p {
          margin: 0;
          font-weight: 400;
          font-size: 18px;
          line-height: 25.2px;
          & a {
            text-decoration: underline;
            &.nounderline {
              text-decoration: none;
            }
            &:hover {
              color: var(--red);
            }
          }
        }
        & div strong {
          color: var(--red);
        }
      }
    }
    & > div#details {
      width: 610px;
      margin-left: 20px;
      & p {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.32px;
        font-weight: 400;
        margin: 0;
        & strong {
          color: var(--red);
        }
      }
    }
    & button {
      margin-top: 20px;
    }
  }
  & > svg {
    position: absolute;
    top: 29px;
    right: 37px;
    cursor: pointer;
  }
  & .contactdetails {
    max-width: 100%;
    overflow-x: hidden;
    & p {
      max-width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      &.canwrap {
        white-space: initial;
        overflow-x: initial;
      }
      a {
        color: var(--black);
      }
    }
  }
  & #details{
    margin-right:35px;
  }
  @media (max-width: 1439px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    & > div {
      height: 100%;
    }
  }
  @media (max-width: 1023px) {
    & > div {
      flex-direction: column !important;
      & > div:first-of-type {
        margin-bottom: 25px;
      }
    }
  }
  @media (max-width: 767px) {
    & > div {
      overflow-y: scroll;

      & > div {
        width: 100% !important;
        margin-left: 0 !important;
        & :first-of-type {
          flex-direction: column !important;
          & > div {
            width: 100% !important;
            margin-left: 0 !important;
            &:first-of-type {
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
`;

export const Scrim = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.125);
  background: #f1f2ef;
  background-blend-mode: multiply;
  transition: 0.5s;
`;
