import styled from "styled-components";

export const RecommendationCardHolder = styled.div`
  --cardWidth: 295px;
  display: flex;
  align-items: flex-end;
`;

export const TodayCardHolder = styled.div`
  --cardWidth: 295px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
  z-index: 1;
  @media (max-width: 1439px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

export const RecommendationCardDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: var(--cardWidth);
  
  & > a {
    & > img {
      width: var(--cardWidth);
      height: auto;
      margin-bottom: 23px;
    }
    & > div {
      width: var(--cardWidth);
      height: var(--cardWidth);
      margin-bottom: 23px;
    }
  }
  & > div {
    & > h6:first-of-type {
      color: var(--white);
      letter-spacing: 1px;
      margin-bottom: 3px;
      & + h6 {
        & span {
          display: inline-block;
          width: 12px;
        }
        & a {
          color: var(--white);
        }
      }
    }
    & > h5 {
      text-transform: initial;
      margin-top: 15px;
      margin-bottom: 4px;
      letter-spacing: 0.25px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      color: var(--black);
      & a {
        color: var(--black);
      }
    }
    & > div {
      min-height: 66px;
      font-size: 12px;
      line-height: 18px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  & + div {
    margin-left: 20px;
  }
  @media (max-width: 589px) {
    margin-bottom: 25px;
    & + div {
      margin-left: 0;
    }
  }
`;

export const RecommendationBlockDiv = styled.div`
  --accentColor: ${(props) =>
    props.accentColor ? props.accentColor : "var(--red)"};
  --color: ${(props) => props.color || "var(--white)"};
  --topMargin: 50px;
  background-color: var(--accentColor);
  width: var(--width);
  min-height: 610px;
  padding: var(--topMargin) var(--outerMargin);
  margin-left: ${(props) =>
    props.normalLeft ? "auto" : "calc(0px - var(--outerMargin))"};
  margin-right: auto;
  color: var(--color);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &.todayontheavenue{
    padding-bottom:110px !important;
  }
  & h3 {
    align-self: flex-start;
    color: var(--color);
    margin-bottom: 25px;
    z-index: 1;
    & a {
      color: var(--color);
      border-bottom: 2px solid var(--color);
    }
  }
  & > a {
    z-index: 1;
  }
  /* @media (min-width: 1441px) { */
  position: relative;
  &:before {
    z-index: 0;
    background-color: var(--accentColor);
    content: "";
    position: absolute;
    height: 100%;
    left: -2000px;
    right: -2000px;
    top: 0;
    bottom: 0;
    /* } */
  }
  @media (max-width: 767px) {
    & h3 {
      margin: 0 25px 25px 25px;
      text-align: center;
      width: calc(100% - 50px);
    }
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (max-width: 589px) {
    --topMargin: 25px;
  }
`;

export const RelatedEventsBlockDiv = styled.div`
  --color: ${(props) => props.color};
  background-color: var(--red);
  min-height: 610px;
  width: 100%;
  padding: 50px var(--outerMargin);
  color: var(--color);
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(
      calc(var(--width) - var(--outerMargin) - var(--outerMargin))
    );
    width: 100%;
    & h3 {
      align-self: flex-start;
      color: var(--white);
      margin-bottom: 25px;
      & a {
        color: var(--white);
        border-bottom: 2px solid var(--white);
      }
    }
  }
  @media (max-width: 1439px) {
    --outerMargin: 25px;
    justify-content: center;
    & > div {
      margin-left: 0;
      margin-right: 0;
      max-width: initial;
      & > div {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
`;
