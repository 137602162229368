import React from "react";
import PropTypes from "prop-types";
import { HeroHomeWrapperNew, CallToActionBox } from "./elements";
import { H4Strong, H1, H3Strong, H3Italic, H4Italic,H4 } from "./../Typography";
import Img from "gatsby-image";
import './style.css';
import { func } from "prop-types"; 
// import { useSwipeable, SwipeableHandlers } from "react-swipeable";

// TODO: https://codesandbox.io/s/lrk6955l79?file=/src/Carousel.js
/**
 *
 * Images are mocked in this! I don't know how they're coming in from Craft. Right now this just takes an element
 *
 * The carousel does not autoscroll right now. Should it be draggable?
 *
 */
const HomeHeroNew = ({
  heros,
  onCallToAction,
  afterPerspectives,
  isPerspectives,
}) => {
  const theHeros = heros.filter((x) => x !== null);
  // console.log(heros.map((x) => x.bannerGraphic[0].wideImage));
  const [currentSlide, setCurrentSlide] = React.useState(2);
  const [notActive, setNotActive] = React.useState('');
  const [active, setActive] = React.useState('0px');


  React.useEffect(() => {
    const next = (currentSlide + 1) % theHeros.length;
    
    const id = setTimeout(() => {
      setCurrentSlide(next)
    }, 3500);
    return () => clearTimeout(id);
  }, [currentSlide]);



  return (
    
    <HeroHomeWrapperNew
      afterPerspectives={afterPerspectives || false}
    >
      <div>
        <div
        >
          {theHeros.map((hero, index) => {
            const myLink = `/${
              hero.typeHandle === "programmes" ? "programme" : hero.typeHandle
            }/${hero.slug}/`;
            const external=hero.externalLink;

            return (
                <div 
                key={index}
                className={currentSlide==index ? "item current" : "item"}
                
                >
                    {hero.bannerGraphic.length &&
                    hero.bannerGraphic[0].wideImage ? (
                    hero.bannerGraphic[0].wideImage.childImageSharp ? (
                      <Img
                      fixed={
                        hero.bannerGraphic[0].wideImage.childImageSharp.fixed
                      }
                      className="desktop"
                    />
                      
                    ) : (
                        <img
                        src={hero.bannerGraphic[0].wideImage.publicURL}
                        className="desktop"
                        />
                    )
                    ) : (
                    <div className="placeholder desktop" />
                    )}
                    {hero.bannerGraphicPortrait &&
                    hero.bannerGraphicPortrait.length &&
                    hero.bannerGraphicPortrait[0].tallImage ? (
                    hero.bannerGraphicPortrait[0].tallImage.childImageSharp ? (
                      <Img
                        fixed={hero.bannerGraphicPortrait[0].tallImage.childImageSharp.fixed}
                        className="mobile"
                      />
                    ) : (
                        <img
                        src={hero.bannerGraphicPortrait[0].tallImage.publicURL}
                        className="mobile"
                        />
                    )
                    ) : (
                    <div className="placeholder mobile" />
                    )}
                    <div className="carosel-overlay">
                            {isPerspectives ? null : (
                            <h4>{hero.bannerTopline}</h4>
                        )}
                        <H1>{hero.bannerTitle}{notActive}</H1>
                        {isPerspectives ? null : (
                            <React.Fragment>
                            <h3>{hero.bannerSubtitleLine1}</h3>
                            <h3>{hero.bannerSubtitleLine2}</h3>
                            <CallToActionBox
                                color={hero.bannerColor || "var(--white)"}
                            >
                                <H4>
                                {hero.openExternalLink==true && hero.externalLink.length>0 ? (
                                  <a
                                    href={external}
                                    target="_blank"
                                  >
                                    {hero.bannerCallToAction || "Find out more"}
                                  </a>
                                ) : (
                                  <a
                                    href="/#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onCallToAction(myLink);
                                    }}
                                  >
                                    {hero.bannerCallToAction || "Find out more"}
                                  </a>
                                )}
                                </H4>
                            </CallToActionBox>
                            </React.Fragment>
                        )}
                        
                    </div>
                    <div className="carosel-overlay-bg"></div>
                </div>
            );
          })}
        </div>
      </div>
      {theHeros.length > 1 ? (
        <nav>
          <ul>
            {theHeros.map((hero, index) => (
              <li
                className={currentSlide === index ? "selected" : ""}
                key={index}
              >
                <a
                  aria-hidden
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentSlide(index);
                  }}
                >
                  {index}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      ) : null}
    </HeroHomeWrapperNew>
  );
};

export default HomeHeroNew;

HomeHeroNew.propTypes = {
  heros: PropTypes.arrayOf(
    PropTypes.shape({
      bannerGraphic: PropTypes.arrayOf(
        PropTypes.shape({ url: PropTypes.string })
      ),
      bannerTopline: PropTypes.string,
      bannerTitle: PropTypes.string,
      bannerSubtitleLine1: PropTypes.string,
      bannerSubtitleLine2: PropTypes.string,
      bannerCallToAction: PropTypes.string,
      slug: PropTypes.string,
      bannerColor: PropTypes.string,
    })
  ),
  onCallToAction: PropTypes.func,
};