import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { HomeAvenueDiv } from "./elements";
import { H3, H4Big, H6Medium,H4Medium } from "./../Typography";
import ArrowRightLine from "./../icons/ArrowRightLine";
import { getDate, getTime, cleanCraftDate } from "./../../modules/time";
import { BigButton } from "./../Buttons";

const PerspectiveTile = ({ title, author, image, link,eventType,eventLocation,startDate,startTime,endDate,endTime }) => {
    const smallLabel=(eventType!=''?eventType+' | ':eventType)+eventLocation;
    
    const isDurational = eventType === "Exhibition" || eventType === "Guest Project";
    const logLine =
    isDurational &&
    eventLocation &&
    eventLocation.length &&
    eventLocation[0] !== null ? '': 'Starts '+startTime+' Ends '+endTime;
    
    const now = new Date();
    const started = new Date(startDate);

    
    
    
    return (
      <div>
        <Link to={link}>
          {image && image.cardImage ? (
            image.cardImage.childImageSharp ? (
              <Img fixed={image.cardImage.childImageSharp.fixed} alt={title} />
            ) : (
              <img src={image.cardImage.publicURL} alt={title} />
            )
          ) : (
            <div className="fallback" />
          )}
        </Link>
        <div>
            <H4Medium style={{color:'var(--green)'}}>
            {isDurational
            ? now < started
              ? `${startDate}–${endDate}`
              : `Now on until ${endDate}`
            : startDate}
            </H4Medium>
          <H4Big style={{fontWeight:'500',color:'var(--white)'}}>
            <Link to={link} style={{color:'var(--white)'}}>{title} </Link>
          </H4Big>
          {smallLabel ? (
            <H6Medium style={{color:'var(--white)',fontWeight:'600',textTransform:'capitalize'}}>
              {smallLabel}
            </H6Medium>
          ) : null}
          <ArrowRightLine color={"var(--green)"} />
        </div>
        
      </div>
    );
  };

const HomeAvenue = ({ tiles, title, onTop, bottomMargin }) => {
    const now = new Date();
    const filteredEvents = tiles.filter((x) => {
    const thisDate = new Date(x.eventEndDateTime);
    return thisDate >= now;
    });

    //console.log(filteredEvents);
  return (
    <HomeAvenueDiv aboveBanner={onTop || false} bottomMargin={bottomMargin}>
      {filteredEvents && filteredEvents.length
        ? <>
        <H3>{title || "IN OUR LANES"}</H3>
        <div>
        {filteredEvents.map((tile,index)=>{
            const eventType=(tile.eventType!=undefined && tile.eventType.length>0?tile.eventType[0]:'');
            const eventLocation=(tile.eventLocation!=undefined && tile.eventLocation.length>0?tile.eventLocation[0].title:'');
            
            const start= new Date(tile.eventStartDateTime);
            const end=new Date(tile.eventEndDateTime);
            return (
                <PerspectiveTile
                    key={index}
                    title={tile.title}
                    link={`/event/${tile.slug}`}
                    image={tile.headerImage[0]}
                    startDate={start.toLocaleDateString("en-gb", {
                                  timeZone: "Asia/Dubai",
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                    endDate={end.toLocaleDateString("en-gb", {
                              timeZone: "Asia/Dubai",
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                    startTime={getTime(tile.eventStartDateTime)}
                    endTime={getTime(tile.eventEndDateTime)}
                    eventType={eventType}
                    eventLocation={eventLocation}
                />
            )
        })}
        </div>
        <div className="all-avenue-link">
        <Link to={`/events/`}>
          <BigButton color={"var(--black)"} style={{fontStyle:'unset'}}>Explore All Events</BigButton>
        </Link>
        </div>
        </>
        : null}
    </HomeAvenueDiv>
  );
};

export default HomeAvenue;
