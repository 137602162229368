import styled from "styled-components";

export const HeroProgrammeDiv = styled.div`
  --backgroundColor: ${(props) => props.backgroundColor || "var(--white)"};
  --color: ${(props) => props.color || "var(--black)"};
  --titleColor: ${(props) => props.titleColor || "var(--black)"};
  --programmeHeight: 610px;
  --programmeWidth: 1440px;
  --topMargin: 115px;
  --titleSize: 170px;
  width: var(--width);
  height: var(--programmeHeight);
  padding: 0 var(--outerMargin);
  margin-left: ${(props) =>
    props.isBanner ? "calc(0px - var(--outerMargin))" : "auto"};
  margin-right: auto;
  color: var(--color);
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: ${(props) => (props.isTop ? "hidden" : "visible")};
  .mobile-programme-banner{
    display:none;
    position:relative;
    @media (max-width:767px){
      display:block;
      .gatsby-image-wrapper{
        width:100vw!important;
      }
    }
  }
  .desktop-programme-banner{
    @media(max-width: 767px)
    {
      display:none;
    }
  }
  .text{
    position:absolute;
    top:0px;
  }
  /* &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      content: "";
      opacity: 0.5;
      background-blend-mode: multiply;
      background-image: linear-gradient(to bottom, var(--red), var(--red));
      pointer-events: none;
      z-index: 2;
    } */

  & > img.fallback {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    & + div {
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  & > .gatsby-image-wrapper {
    position: absolute !important;
    width: var(--programmeWidth) !important;
    height: var(--programmeHeight) !important;
    z-index: 1;
    & + div {
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  & svg {
    margin-top: var(--topMargin);
    margin-bottom: 20px;
  }
  & div.text {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100vw;
    //mix-blend-mode: difference;
    height: 100%;
    justify-content: center;
    & > h5:first-child {
      margin-top: var(--topMargin);
    }

    & h1 {
      user-select: none;
      color: var(--white);
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      margin-bottom: 80px;
      font-size: var(--titleSize); /* this shouldn't be here! */
      text-align: center;
      & a {
        color: var(--white);
      }
    }
    & a.titleimage {
      position: absolute;
      top: 0;
      & .gatsby-image-wrapper {
        width: var(--programmeWidth) !important;
        height: var(--programmeHeight) !important;
      }
    }
    & h5 {
      max-width: 900px;
      text-align: center;
      /* color: var(--titleColor); */
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
  }
  & h5 {
    margin-bottom: 50px;
  }
  @media (max-width: 1439px) {
    /* overflow: hidden; */
    --titleSize: 120px;
  }
  @media (max-width: 1023px) {
    --programmeHeight: 480px;
    --programmeWidth: 1024px;
    --topMargin: 75px;
    --titleSize: 80px;
  }
  @media (max-width: 767px) {
    /* --programmeHeight: 360px;
    --programmeWidth: 767px; */
    --titleSize: 70px;
    --topMargin: 25px;
    --topMargin: 75px;
    /* --programmeHeight: 500px; */
    & svg {
      visibility: hidden;
      margin-bottom: 0;
    }
    & + div.programmesubtitle {
      --outerMargin: 10px;
      & h3 {
        font-size: 18px;
        line-height: 1.5;
      }
    }
  }
  @media (min-width: 1441px) {
    --programmeHeight: 42.3611111111vw;
    --programmeWidth: 100vw;
    /*--programmeHeight: 42.3611111111vw;
    position: relative;
    height: var(--programmeHeight);
    & > div {
      position: absolute;
      left: calc(0px - calc(calc(100vw - 1440px) / 2));
      width: var(--programmeWidth);
      height: var(--programmeHeight);
    }
    & > div > div > div {
      height: var(--programmeHeight);
    } */
    & .gatsby-image-wrapper {
      --programmeWidth: 100vw;
      --programmeHeight: 42.3611111111vw;
      width: var(--programmeWidth) !important;
      height: var(--programmeHeight) !important;
      /* position: absolute !important; */
      /* left: calc(0px - calc(calc(100vw - 1440px) / 2)); */
      top: 0;
    }
  }
`;
