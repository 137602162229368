import React from "react";
import { ProgrammeBodyDiv } from "./elements";

const ProgrammeBody = ({ children, noBottom, eventsPage,accentColor }) => 
(
  <ProgrammeBodyDiv noBottom={noBottom} eventsPage={eventsPage} accentColor={accentColor || "var(--white)"}>
    <div>{children}</div>
  </ProgrammeBodyDiv>
);

export default ProgrammeBody;
