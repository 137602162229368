import styled from "styled-components";

export const SmallButtonDiv = styled.button`
  --color: ${(props) => props.color || "var(--slate)"};
  --colorDisabled: "var(--mid)";
  --backgroundColorDisabled: "transparent";
  --backgroundColor: ${(props) => props.backgroundColor || "var(--white)"};
  --hoverColor: "var(--red)";
  --hoverColorDisabled: "var(--mid)";
  display: inline-block;
  min-width: 65px;
  min-height: 28px;
  padding: 5px 20px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.24px;
  font-family: var(--headerFont);
  font-weight: 500;
  align-items: center;
  justify-content: center;
  display: flex;
  user-select: none;
  color: ${(props) =>
    props.disabled ? "var(--colorDisabled)" : "var(--color)"};
  border: solid 1.5px
    ${(props) => (props.disabled ? "var(--colorDisabled)" : "var(--color)")};
  background-color: ${(props) =>
    props.disabled
      ? "var(--backgroundColorDisabled)"
      : "var(--backgroundColor)"};
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  &:hover {
    color: ${(props) =>
      props.disabled ? "var(--hoverColorDisabled)" : "var(--hoverColor)"};
    border-color: ${(props) =>
      props.disabled ? "var(--hoverColorDisabled)" : "var(--hoverColor)"};
  }
  &:focus {
    appearance: none;
    outline: none;
  }
`;

export const BigButtonDiv = styled.button`
  --color: ${(props) => props.color || "var(--slate)"};
  --colorDisabled: "var(--mid)";
  --backgroundColorDisabled: "transparent";
  --backgroundColor: ${(props) => props.backgroundColor || "transparent"};
  --hoverColor: "var(--red)";
  --hoverColorDisabled: "var(--mid)";
  display: inline-block;
  min-width: 65px;
  min-height: 28px;
  padding: 5px 20px;
  font-size: 21px;
  font-weight: 500;
  font-style: italic;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  letter-spacing: 1.26px;
  font-family: var(--headerFont);
  text-transform: uppercase;
  font-weight: 500;
  user-select: none;
  color: ${(props) =>
    props.disabled ? "var(--colorDisabled)" : "var(--color)"};
  border: solid 1.5px
    ${(props) => (props.disabled ? "var(--colorDisabled)" : "var(--color)")};
  background-color: ${(props) =>
    props.disabled
      ? "var(--backgroundColorDisabled)"
      : "var(--backgroundColor)"};
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  &:hover {
    color: ${(props) =>
      props.disabled
        ? "var(--hoverColorDisabled, var(--mid))"
        : "var(--hoverColor,var(--red))"};
    border-color: ${(props) =>
      props.disabled
        ? "var(--hoverColorDisabled, var(--mid))"
        : "var(--hoverColor),var(--red)"};
  }
  &:focus {
    appearance: none;
    outline: none;
  }
`;
