import styled from "styled-components";

// n.b. this is used for everything on the home page.

export const ProgrammeBodyDiv = styled.div`
--accentColor: ${(props) =>
  props.accentColor ? props.accentColor : "var(--white)"};
  max-width: var(--width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--accentColor);
  padding: 0 var(--outerMargin) 25px var(--outerMargin);
  height: 100%;
  padding-bottom: ${(props) => (props.noBottom ? 0 : "25px")};
  padding-top: 25px;
  z-index: ${(props) => (props.eventsPage ? 1 : "initial")};
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    background-color: var(--accentColor);
    left: -100vw;
    right: -100vw;
    z-index: 0;
  }
  & > div {
    // mix-blend-mode: darken;
    z-index: 1;
    ${(props) => props.eventsPage && "mix-blend-mode: darken;"};
  }
  & ul {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1439px) {
    --outerMargin: 50px;
  }
  @media (max-width: 1023px) {
    --outerMargin: 25px;
  }
  @media (max-width: 767px) {
    --outerMargin: 0;
    & > div > h1 {
      // events page
      font-size: var(--textSize);
      padding-left: 25px;
      padding-right: 25px;
    }
  }
`;
