import styled, { createGlobalStyle } from "styled-components";

export const BodyNoScroll = createGlobalStyle`
body {
	overflow-y: hidden;
}
body::-webkit-scrollbar {
	display: none;
}
`;

export const ArtistTakeoverWrapper = styled.div`
  position: fixed;
  display: block;
  background-color: var(--black);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--white);
  z-index: 9;
  overflow: hidden;
  --accentColor: var(--red);
  ::-webkit-scrollbar {
    display: none;
  }

  & > svg {
    position: absolute;
    right: 50px;
    top: 50px;
  }
  & > div {
    padding: 50px var(--outerMargin) 200px var(--outerMargin);
    z-index: 999;
    overflow-y: auto;
    pointer-events: all;
    max-height: calc(100% + var(--outerMargin));
    & > img {
      border-top: 4px solid var(--accentColor);
    }
    & > div {
      max-width: calc(
        calc(var(--width) - var(--outerMargin)) - var(--outerMargin)
      );
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      & > button,
      & > a {
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        margin-bottom: 50px;
        flex: 2;
        & > button {
        }
      }

      & > div:first-of-type {
        display: flex;
        color: var(--accentColor);
        justify-content: space-between;
        margin-bottom: 25px;
        & h1,
        & h2 {
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    --outerMargin: 10px;
    & > svg {
      top: 10px;
      right: 10px;
    }
    & > div > div > div:first-of-type {
      flex-direction: column;
    }
  }
`;
