import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";
import GlobalContext from "./../GlobalContext";
import { getDate, getTime, cleanCraftDate } from "./../../modules/time";
import { getEventTypeFromID } from "./../../modules/eventTypes";
import { H3, H6, H6SmallMedium, H5SmallStrong } from "./../Typography";
import { BigButton } from "./../Buttons";
import {
  RecommendationBlockDiv,
  RecommendationCardHolder,
  RecommendationCardDiv,
  TodayCardHolder,
  RelatedEventsBlockDiv,
} from "./elements";

const RecommendationCard = ({
  image,
  eventType,
  startDate,
  startTime,
  eventLocation,
  endDate,
  endTime,
  title,
  eventShortDescription,
  slug,
}) => {
  // console.log(eventType, eventLocation, eventLocation[0] !== null);
  const isDurational =
    eventType === "Exhibition" || eventType === "Guest Project";
  const logLine =
    isDurational &&
    eventLocation &&
    eventLocation.length &&
    eventLocation[0] !== null ? (
      <H6>
        <Link to={`/community/${eventLocation[0].slug}/`}>
          {eventLocation[0].title}
        </Link>
      </H6>
    ) : (
      <H6>
        Starts {startTime}
        <span />
        Ends {endTime}
      </H6>
    );
  const now = new Date();
  const started = new Date(startDate);
  // console.log(title, endDate, isDurational, now > started);
  return (
    <RecommendationCardDiv>
      <Link to={`/event/${slug}/`}>
        {image && image.cardImage ? (
          image.cardImage.childImageSharp ? (
            <Img fixed={image.cardImage.childImageSharp.fixed} alt={title} />
          ) : (
            <img src={image.cardImage.publicURL} alt={title} />
          )
        ) : image && image.url ? (
          <img src={image.url} alt={title} />
        ) : (
          <div className="fallback" />
        )}
      </Link>
      <div>
        <H6SmallMedium>
          {eventType} |{" "}
          {isDurational
            ? now < started
              ? `${startDate}–${endDate}`
              : `Now on until ${endDate}`
            : startDate}
        </H6SmallMedium>
        {logLine}
        <H5SmallStrong>
          <Link to={`/event/${slug}/`}>{title}</Link>
        </H5SmallStrong>
        <div dangerouslySetInnerHTML={{ __html: eventShortDescription }} />
      </div>
    </RecommendationCardDiv>
  );
};
const RecommendationBlock = ({ name, events, accentColor }) => (
  <RecommendationBlockDiv accentColor={accentColor}>
    <H3>
      <Link to={`/team/${name.slug}/`}>{name.title}</Link> recommends:
    </H3>
    <RecommendationCardHolder>
      {events.map((thisEvent, index) => (
        <RecommendationCard
          key={index}
          image={thisEvent.headerImage?thisEvent.headerImage[0]:""}
          title={thisEvent.title}
          eventLocation={thisEvent.eventLocation}
          startDate={thisEvent.eventStartDateTim?getDate(thisEvent.eventStartDateTime):""}
          eventShortDescription={thisEvent.eventShortDescription}
          startTime={thisEvent.eventStartDateTime?getTime(thisEvent.eventStartDateTime):""}
          endTime={thisEvent.eventEndDateTime?getTime(thisEvent.eventEndDateTime):""}
          eventType={thisEvent.eventType?getEventTypeFromID(thisEvent.eventType[0]):""}
          slug={thisEvent.slug}
        />
      ))}
    </RecommendationCardHolder>
  </RecommendationBlockDiv>
);

export const RelatedEventsBlock = ({ header, relatedEvents }) => {
  const { useStaging } = React.useContext(GlobalContext);
  const now = new Date();
  const filteredEvents = relatedEvents
    .filter(useStaging ? (x) => x : (x) => !x.isStaging)
    .filter((x) => cleanCraftDate(x.eventEndDateTime) > now);
  return filteredEvents && filteredEvents.length ? (
    <RelatedEventsBlockDiv>
      <div>
        <H3>{header}</H3>
        <RecommendationCardHolder>
          {filteredEvents.map((thisEvent, index) => (
            <RecommendationCard
              key={index}
              image={thisEvent.headerImage[0]}
              title={thisEvent.title}
              eventLocation={thisEvent.eventLocation}
              startDate={getDate(thisEvent.eventStartDateTime)}
              endDate={getDate(thisEvent.eventEndDateTime)}
              eventShortDescription={thisEvent.eventShortDescription}
              startTime={getTime(thisEvent.eventStartDateTime)}
              endTime={getTime(thisEvent.eventEndDateTime)}
              eventType={getEventTypeFromID(thisEvent.eventType[0])}
              slug={thisEvent.slug}
            />
          ))}
        </RecommendationCardHolder>
        <Link to={`/events/`}>
          <BigButton color={"var(--white)"}>Explore All Events</BigButton>
        </Link>
      </div>
    </RelatedEventsBlockDiv>
  ) : null;
};

export const TodayOnTheAvenueBlock = ({ events, noButton, title,accentColor,color }) => {
  const now = new Date();
  const filteredEvents = events.filter((x) => {
    const thisDate = new Date(x.eventEndDateTime);
    return thisDate >= now;
  });
  const thisColor=
  console.log(accentColor);
  return filteredEvents && filteredEvents.length ? (
    <RecommendationBlockDiv className="todayontheavenue" accentColor={accentColor || "var(--red)"} color={color || "var(--white)"}>
      <H3>{title ? title : "Currently on the Avenue"}</H3>
      <TodayCardHolder>
        {filteredEvents && filteredEvents.length
          ? filteredEvents
              .slice(0, 4)
              .map((thisEvent, index) => (
                <RecommendationCard
                  key={index}
                  image={thisEvent.headerImage[0]}
                  title={thisEvent.title}
                  eventLocation={thisEvent.eventLocation}
                  startDate={getDate(thisEvent.eventStartDateTime)}
                  eventShortDescription={thisEvent.eventShortDescription}
                  startTime={getTime(thisEvent.eventStartDateTime)}
                  endDate={getDate(thisEvent.eventEndDateTime)}
                  endTime={getTime(thisEvent.eventEndDateTime)}
                  eventType={getEventTypeFromID(thisEvent.eventType[0])}
                  slug={thisEvent.slug}
                />
              ))
          : null}
      </TodayCardHolder>
      <TodayCardHolder>
        {filteredEvents && filteredEvents.length
          ? filteredEvents
              .slice(4, 8)
              .map((thisEvent, index) => (
                <RecommendationCard
                  key={index}
                  image={thisEvent.headerImage[0]}
                  title={thisEvent.title}
                  eventLocation={thisEvent.eventLocation}
                  startDate={getDate(thisEvent.eventStartDateTime)}
                  eventShortDescription={thisEvent.eventShortDescription}
                  startTime={getTime(thisEvent.eventStartDateTime)}
                  endDate={getDate(thisEvent.eventEndDateTime)}
                  endTime={getTime(thisEvent.eventEndDateTime)}
                  eventType={getEventTypeFromID(thisEvent.eventType[0])}
                  slug={thisEvent.slug}
                />
              ))
          : null}
      </TodayCardHolder>
      {noButton ? null : (
        <Link to={`/events/`}>
          <BigButton color={color || "var(--white)"}>Explore All Events</BigButton>
        </Link>
      )}
    </RecommendationBlockDiv>
  ) : null;
};

export const WhiteRecommendationBlock = ({ events, title }) => (
  <RecommendationBlockDiv
    accentColor={"var(--white)"}
    color={"var(--black)"}
    normalLeft
  >
    <H3>{title ? title : "Currently on the Avenue"}</H3>
    <TodayCardHolder>
      {events && events.length
        ? events
            .slice(0, 4)
            .map((thisEvent, index) => (
              <RecommendationCard
                key={index}
                image={thisEvent.headerImage[0]}
                title={thisEvent.title}
                startDate={getDate(thisEvent.eventStartDateTime)}
                eventShortDescription={thisEvent.eventShortDescription}
                startTime={getTime(thisEvent.eventStartDateTime)}
                endTime={getTime(thisEvent.eventEndDateTime)}
                eventType={getEventTypeFromID(thisEvent.eventType[0])}
                slug={thisEvent.slug}
              />
            ))
        : null}
    </TodayCardHolder>
    <TodayCardHolder>
      {events && events.length
        ? events
            .slice(4, 8)
            .map((thisEvent, index) => (
              <RecommendationCard
                key={index}
                image={thisEvent.headerImage[0]}
                title={thisEvent.title}
                startDate={getDate(thisEvent.eventStartDateTime)}
                eventShortDescription={thisEvent.eventShortDescription}
                startTime={getTime(thisEvent.eventStartDateTime)}
                endTime={getTime(thisEvent.eventEndDateTime)}
                eventType={getEventTypeFromID(thisEvent.eventType[0])}
                slug={thisEvent.slug}
              />
            ))
        : null}
    </TodayCardHolder>
  </RecommendationBlockDiv>
);

export default RecommendationBlock;

RecommendationBlock.propTypes = {
  name: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }),
  events: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
};
