import styled from "styled-components";

export const HeroHomeWrapperNew = styled.div`
width: 90%;
position: relative;
overflow: hidden;
min-height: 800px;
background-color:#fff;
margin:0 auto 90px;
@media screen and (max-width:767px){
  min-height: 700px;
}
& div{
    & div{
      user-select: none;
      //position: absolute;
      inset: 0px;
      //width: 100%;
      //height: 100%;
      z-index: 2;
      // padding: var(--titleDrop) var(--outerMargin) 0 var(--outerMargin);
      // background-blend-mode: multiply;
      // background-image: linear-gradient(to top, rgba(16, 24, 32, 0.25), rgba(16, 24, 32, 0));
      color: #fff;
      // padding: 150px 50px 50px 50px;
    }
    
}
& .item.current{
    display:block;
}

& .item{
  display:none;
  position: absolute;
  width: 100%;
  height: 100%;
    & .gatsby-image-wrapper{
      width:100% !important;
      height: 100% !important;
    }
    & img{
      width: 100%;
      height: 100%;
      object-fit: fill !important;
    }
    & .mobile {
        display: none !important;
    }
    & .carosel-overlay-bg::before{
      content: '';
      user-select: none;
      position: absolute; 
      inset: 0px;
      width: 100%;
      // max-width:1280px;
      height: 100%;
      z-index: 2;
      padding: var(--titleDrop) var(--outerMargin) 0 var(--outerMargin);
      background-blend-mode: multiply;
      background-image: linear-gradient(to top, rgba(16, 24, 32, 0.25), rgba(16, 24, 32, 0));
      color: #fff;
      padding: 150px 20px 50px 20px;
      transform: translateX(-50%);
      left: 50%;
    }
    & .carosel-overlay{
        user-select: none;
        position: absolute; 
        inset: 0px;
        width: 100%;
        max-width:1280px;
        height: 100%;
        z-index: 4;
        padding: var(--titleDrop) var(--outerMargin) 0 var(--outerMargin);
        //background-blend-mode: multiply;
       // background-image: linear-gradient(to top, rgba(16, 24, 32, 0.25), rgba(16, 24, 32, 0));
        color: #fff;
        padding: 150px 20px 50px 20px;
        transform: translateX(-50%);
        left: 50%;
        & h4{
          font-weight: 600;
          font-size: 21px;
          line-height: 1.5;
          letter-spacing: 1.26px;
          text-transform: uppercase;
          margin: 0px;
        }
        & h1{
            font-size: 110px;
            margin-top: 30px;
            margin-bottom: 20px;
        }
        & h3{
            font-weight: 600;
            font-size: 32px;
            line-height: 1.25;
            letter-spacing: 0.32px;
            margin: 0px;
        }
        & a{
          color: #fff;
          display: inline-block;
          border: 1px solid #fff;
          margin-top: 125px;
          font-weight: 500;
          font-size: 15px;
          // font-style: italic;
          line-height: 1.5;
          letter-spacing: 1.26px;
          text-transform: uppercase;
          text-decoration: none;
          padding: 14px 24px;
        }
    }
    @media screen and (max-width:1200px){
        & .carosel-overlay{
            & h1{
                font-size: 65px;
            }
        }
    }
    @media screen and (max-width:767px){
      & .desktop {
        display: none !important;

    }
    & .carosel-overlay{
        & h1{
            font-size: 40px;
        }
    }
      & .mobile {
          display: block !important;
      }
    
  }
    
}
& > nav {
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    position: absolute;
    user-select: none;

    bottom: 50px;
    @media (max-height: 800px) {
    //   position: fixed;
    //   bottom: 75px;
    }
    & ul {
      list-style-type: none;
      display: inline-flex;
      margin: 0;
      padding: 0;
      & li {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        transition: 0.5s;
        background-color: var(--white);
        &.selected {
          width: 36px;
        }
        & + li {
          margin-left: 10px;
        }
        & a {
          display: inline-block;
          width: 100%;
          height: 100%;
          color: transparent;
        }
      }
    }
`;

export const CallToActionBox = styled.div`
  --color: ${(props) => props.color || "var(--white)"};
  display: inline-block;
  //border: 1px solid var(--color);
  margin-top: var(--calloutDrop);
  & a {
    color: var(--color);
    display: inline-block;
    padding: 14px 24px;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
    text-align: center;
  }
`;